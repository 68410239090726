import React, { useState, useLayoutEffect, useMemo, useContext } from 'react';
import { RicosViewerContext, RicosContext } from 'ricos-context';
import { R as RICOS_DEFAULTS, I as IMAGE_TYPE$1 } from './viewer-loadable2.js';
import { isNumber, upperFirst, camelCase, isFunction } from 'lodash';
import { PluginContainerData_Width_Type, PluginContainerData_Alignment } from 'ricos-schema';
import { IMAGE_TYPE } from 'ricos-content';
import classNames from 'classnames';
import { Image as Image$2 } from 'wix-rich-content-ui-components';
import '@loadable/component';
import 'ricos-types';

var styles$1 = {"alignLeft":"CzrNB","textWrapNowrap":"FEGk8","alignRight":"afo53","alignCenter":"dA1OC","sizeFullWidth":"BKu-H","renderedInTable":"nRVXC","sizeOriginal":"d1zX4","sizeContent":"wTAHe","sizeSmall":"iGPM8","sizeSmallCenter":"FZod7","flex":"-SFf-"};

var alignmentClassName = function alignmentClassName(node) {
  var _a, _b;
  var _ref = ((_a = node.imageData) == null ? void 0 : _a.containerData) || {},
    alignment = _ref.alignment,
    width = _ref.width;
  if (!alignment) {
    return "";
  }
  var align = alignment;
  if ((width == null ? void 0 : width.size) === PluginContainerData_Width_Type.ORIGINAL && alignment !== PluginContainerData_Alignment.CENTER) {
    var _ref2 = ((_b = node.imageData) == null ? void 0 : _b.image) || {},
      width2 = _ref2.width;
    if (isNumber(width2) && width2 > 350) {
      align = PluginContainerData_Alignment.CENTER;
    }
  }
  return styles$1["align" + upperFirst(align.toLowerCase())];
};
var sizeClassName = function sizeClassName(node, isMobile) {
  var _a;
  var _ref3 = ((_a = node.imageData) == null ? void 0 : _a.containerData) || {},
    width = _ref3.width;
  if (!(width == null ? void 0 : width.size) || isMobile && (width == null ? void 0 : width.size) === PluginContainerData_Width_Type.ORIGINAL) {
    return "";
  }
  return (width == null ? void 0 : width.size) === PluginContainerData_Width_Type.FULL_WIDTH ? styles$1.sizeFullWidth : styles$1["size" + upperFirst(camelCase(width.size.toLowerCase()))];
};
var textWrapClassName = function textWrapClassName(node) {
  var _a;
  var _ref4 = ((_a = node.imageData) == null ? void 0 : _a.containerData) || {},
    textWrap = _ref4.textWrap;
  if (textWrap) {
    return "";
  }
  return styles$1.textWrapNowrap;
};

var getExpandFunction = function getExpandFunction(_ref) {
  var helpers = _ref.helpers,
    config = _ref.config,
    nodeId = _ref.nodeId,
    imageData = _ref.imageData;
  var onExpand = config.onExpand,
    disableExpand = config.disableExpand;
  if ((imageData == null ? void 0 : imageData.disableExpand) === true || disableExpand === true || !onExpand) {
    return;
  }
  return function (e) {
    var _a;
    e.preventDefault();
    (_a = helpers.onViewerAction) == null ? void 0 : _a.call(helpers, IMAGE_TYPE, "Click", "expand_image");
    onExpand(nodeId);
  };
};

var getImageClickActionConfig = function getImageClickActionConfig(_ref) {
  var imageData = _ref.imageData,
    config = _ref.config,
    expand = _ref.expand;
  var _a, _b;
  if (((_a = imageData == null ? void 0 : imageData.link) == null ? void 0 : _a.url) && config) {
    return {
      type: "link"
    };
  }
  if ((_b = imageData == null ? void 0 : imageData.link) == null ? void 0 : _b.anchor) {
    return {
      type: "anchor"
    };
  }
  if (expand) {
    return {
      type: "expand",
      onClick: expand
    };
  }
  return {
    type: "regular"
  };
};

var styles = {"fontElementMap":"_-1iVh","imageContainer":"W4L8O","expandContainer":"N-pBK","expandIcon":"jZzGw","pointer":"txotc","imageWrapper":"OWQAo","imageRatio":"JasCM","image":"_5-DWt","imageOverlay":"lWYqM","imageCaption":"DnYsh","imagePreload":"-y544","imageHighres":"_0qxFg","onlyHighRes":"FOuic","image_loading":"lxk7e"};

function _createForOfIteratorHelperLoose$4(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$4(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$4(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$4(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$4(r, a) : void 0; } }
function _arrayLikeToArray$4(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$4 = Object.defineProperty;
var __getOwnPropSymbols$4 = Object.getOwnPropertySymbols;
var __hasOwnProp$4 = Object.prototype.hasOwnProperty;
var __propIsEnum$4 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$4 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$4(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$4 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$4.call(b, prop)) __defNormalProp$4(a, prop, b[prop]);
  if (__getOwnPropSymbols$4) {
    for (var _iterator = _createForOfIteratorHelperLoose$4(__getOwnPropSymbols$4(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$4.call(b, prop)) __defNormalProp$4(a, prop, b[prop]);
    }
  }
  return a;
};
var expand = function expand(props) {
  return /* @__PURE__ */React.createElement("svg", __spreadValues$4({
    viewBox: "0 0 19 19",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), /* @__PURE__ */React.createElement("path", {
    d: "M15.071 8.371V4.585l-4.355 4.356a.2.2 0 0 1-.283 0l-.374-.374a.2.2 0 0 1 0-.283l4.356-4.355h-3.786a.2.2 0 0 1-.2-.2V3.2c0-.11.09-.2.2-.2H16v5.371a.2.2 0 0 1-.2.2h-.529a.2.2 0 0 1-.2-.2zm-6.5 6.9v.529a.2.2 0 0 1-.2.2H3v-5.371c0-.11.09-.2.2-.2h.529c.11 0 .2.09.2.2v3.786l4.355-4.356a.2.2 0 0 1 .283 0l.374.374a.2.2 0 0 1 0 .283L4.585 15.07h3.786c.11 0 .2.09.2.2z",
    fill: "#000",
    fillRule: "nonzero"
  }));
};

var ROOT = "imageViewer";

function _createForOfIteratorHelperLoose$3(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$3(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$3(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$3(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$3(r, a) : void 0; } }
function _arrayLikeToArray$3(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$3 = Object.defineProperty;
var __defProps$2 = Object.defineProperties;
var __getOwnPropDescs$2 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols$3 = Object.getOwnPropertySymbols;
var __hasOwnProp$3 = Object.prototype.hasOwnProperty;
var __propIsEnum$3 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$3 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$3(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$3 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$3.call(b, prop)) __defNormalProp$3(a, prop, b[prop]);
  if (__getOwnPropSymbols$3) {
    for (var _iterator = _createForOfIteratorHelperLoose$3(__getOwnPropSymbols$3(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$3.call(b, prop)) __defNormalProp$3(a, prop, b[prop]);
    }
  }
  return a;
};
var __spreadProps$2 = function __spreadProps(a, b) {
  return __defProps$2(a, __getOwnPropDescs$2(b));
};
var ImageContainer = function ImageContainer(props) {
  var type = props.type,
    disableDownload = props.disableDownload,
    children = props.children;
  var hasOnClick = ("onClick" in props);
  var onClick = hasOnClick ? props.onClick : void 0;
  var onKeyDown = onClick ? function (e) {
    if (e.key === "Enter" || e.key === " ") {
      onClick(e);
    }
  } : void 0;
  var interactions = {
    onClick: onClick,
    onKeyDown: onKeyDown,
    onContextMenu: disableDownload
  };
  var role = hasOnClick || type === "regular" && !!disableDownload ? "button" : void 0;
  var tabIndex = role === "button" ? 0 : void 0;
  return /* @__PURE__ */React.createElement("figure", __spreadProps$2(__spreadValues$3({}, role === "button" ? interactions : {}), {
    role: role,
    "data-hook": ROOT,
    tabIndex: tabIndex,
    className: styles.imageContainer,
    "aria-haspopup": role === "button" ? true : void 0
  }), children);
};

var useCustomWidthStyle = function useCustomWidthStyle(_ref) {
  var containerData = _ref.containerData,
    isMobile = _ref.isMobile,
    width = _ref.width;
  var _a, _b;
  return ((_a = containerData == null ? void 0 : containerData.width) == null ? void 0 : _a.size) === "ORIGINAL" || isMobile && (Number((_b = containerData == null ? void 0 : containerData.width) == null ? void 0 : _b.custom) || 0) > 150 ? {
    width: width != null ? width : "0px",
    maxWidth: "100%"
  } : {};
};

var IMAGE_FALLBACK_WIDTH = 300;
var useFallbackWidthStyle = function useFallbackWidthStyle(_ref) {
  var containerData = _ref.containerData,
    isMobile = _ref.isMobile;
  var _a, _b, _c;
  var shouldUseFallbackWidth = ((containerData == null ? void 0 : containerData.alignment) === "LEFT" || (containerData == null ? void 0 : containerData.alignment) === "RIGHT") && !((_a = containerData.width) == null ? void 0 : _a.custom) && ((_b = containerData.width) == null ? void 0 : _b.size) !== "SMALL" && ((_c = containerData.width) == null ? void 0 : _c.size) !== "ORIGINAL" && !isMobile;
  return shouldUseFallbackWidth ? {
    width: IMAGE_FALLBACK_WIDTH + "px"
  } : {};
};

var useImageDimensions = function useImageDimensions(_ref) {
  var src = _ref.src,
    containerData = _ref.containerData,
    image = _ref.image;
  var _a, _b;
  var _useState = useState({
      width: void 0,
      height: void 0
    }),
    dimensions = _useState[0],
    setDimensions = _useState[1];
  var width = (image == null ? void 0 : image.width) || Number((_a = containerData == null ? void 0 : containerData.width) == null ? void 0 : _a.custom) || dimensions.width;
  var height = (image == null ? void 0 : image.height) || Number((_b = containerData == null ? void 0 : containerData.height) == null ? void 0 : _b.custom) || dimensions.height;
  useLayoutEffect(function () {
    if (typeof width === "number" && typeof height === "number") {
      return;
    }
    setDimensions({
      width: void 0,
      height: void 0
    });
    if (!src) {
      return;
    }
    var newImg = new Image();
    newImg.onload = function () {
      return setDimensions({
        width: newImg.width,
        height: newImg.height
      });
    };
    newImg.src = src;
    return function () {
      newImg.onload = null;
    };
  }, [src]);
  return {
    width: width,
    height: height
  };
};

function _createForOfIteratorHelperLoose$2(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$2(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$2(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$2(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$2(r, a) : void 0; } }
function _arrayLikeToArray$2(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$2 = Object.defineProperty;
var __defProps$1 = Object.defineProperties;
var __getOwnPropDescs$1 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols$2 = Object.getOwnPropertySymbols;
var __hasOwnProp$2 = Object.prototype.hasOwnProperty;
var __propIsEnum$2 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$2 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$2(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$2 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$2.call(b, prop)) __defNormalProp$2(a, prop, b[prop]);
  if (__getOwnPropSymbols$2) {
    for (var _iterator = _createForOfIteratorHelperLoose$2(__getOwnPropSymbols$2(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$2.call(b, prop)) __defNormalProp$2(a, prop, b[prop]);
    }
  }
  return a;
};
var __spreadProps$1 = function __spreadProps(a, b) {
  return __defProps$1(a, __getOwnPropDescs$1(b));
};
var useImageSocialAttributes = function useImageSocialAttributes(_ref) {
  var src = _ref.src,
    config = _ref.config,
    width = _ref.width,
    height = _ref.height;
  if (src === void 0 || (config == null ? void 0 : config.imageProps) === void 0) {
    return {};
  }
  var imageProps = isFunction(config.imageProps) ? config.imageProps({
    url: src.url,
    file_name: src.id,
    width: width,
    height: height
  }) : config.imageProps;
  return ["data-pin-nopin", "data-pin-url", "data-pin-media"].reduce(function (socialAttributes, attribute) {
    if (imageProps[attribute]) {
      var _spreadProps;
      return __spreadProps$1(__spreadValues$2({}, socialAttributes), (_spreadProps = {}, _spreadProps[attribute] = imageProps[attribute], _spreadProps));
    }
    return socialAttributes;
  }, {});
};

function _createForOfIteratorHelperLoose$1(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$1(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$1(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$1(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$1(r, a) : void 0; } }
function _arrayLikeToArray$1(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$1 = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols$1 = Object.getOwnPropertySymbols;
var __hasOwnProp$1 = Object.prototype.hasOwnProperty;
var __propIsEnum$1 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$1 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$1(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$1 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$1.call(b, prop)) __defNormalProp$1(a, prop, b[prop]);
  if (__getOwnPropSymbols$1) {
    for (var _iterator = _createForOfIteratorHelperLoose$1(__getOwnPropSymbols$1(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$1.call(b, prop)) __defNormalProp$1(a, prop, b[prop]);
    }
  }
  return a;
};
var __spreadProps = function __spreadProps(a, b) {
  return __defProps(a, __getOwnPropDescs(b));
};
var Image$1 = function Image(_ref) {
  var _classNames, _spreadValues;
  var dataUrl = _ref.dataUrl,
    node = _ref.node,
    className = _ref.className,
    config = _ref.config,
    helpers = _ref.helpers,
    isMobile = _ref.isMobile,
    seoMode = _ref.seoMode,
    imageClickActionConfig = _ref.imageClickActionConfig,
    expand$1 = _ref.expand,
    captionComponent = _ref.captionComponent,
    shouldLazyLoadImages = _ref.shouldLazyLoadImages;
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p;
  var containerData = ((_a = node.imageData) == null ? void 0 : _a.containerData) || RICOS_DEFAULTS.containerData;
  var _ref2 = node.imageData || {},
    image = _ref2.image,
    altText = _ref2.altText;
  var _ref3 = image || {},
    src = _ref3.src;
  var disableDownload = ((_b = node.imageData) == null ? void 0 : _b.disableDownload) === true ? function (e) {
    return e.preventDefault();
  } : void 0;
  var imageSource = useMemo(function () {
    if (dataUrl) {
      return dataUrl;
    }
    var _ref4 = src || {},
      url = _ref4.url,
      id = _ref4.id;
    if (url) {
      return url;
    }
    var _ref5 = helpers != null ? helpers : {},
      getImageUrl = _ref5.getImageUrl;
    if (getImageUrl && id) {
      return getImageUrl({
        file_name: id
      });
    }
    return id;
  }, [dataUrl, (_e = (_d = (_c = node.imageData) == null ? void 0 : _c.image) == null ? void 0 : _d.src) == null ? void 0 : _e.url, (_h = (_g = (_f = node.imageData) == null ? void 0 : _f.image) == null ? void 0 : _g.src) == null ? void 0 : _h.id, helpers == null ? void 0 : helpers.getImageUrl]);
  var _useImageDimensions = useImageDimensions({
      src: imageSource,
      containerData: containerData,
      image: image
    }),
    width = _useImageDimensions.width,
    height = _useImageDimensions.height;
  var fallbackWidthStyle = useFallbackWidthStyle({
    containerData: containerData,
    isMobile: isMobile
  });
  var customWidthStyle = useCustomWidthStyle({
    containerData: containerData,
    isMobile: isMobile,
    width: width
  });
  var socialAttributes = useImageSocialAttributes({
    config: config,
    src: src,
    width: image == null ? void 0 : image.width,
    height: image == null ? void 0 : image.height
  });
  var doDimensionsExist = width !== void 0 && height !== void 0;
  return /* @__PURE__ */React.createElement(ImageContainer, __spreadProps(__spreadValues$1({}, imageClickActionConfig), {
    disableDownload: disableDownload
  }), doDimensionsExist && imageSource && /* @__PURE__ */React.createElement(Image$2, __spreadValues$1({
    id: (_l = (_k = (_j = (_i = node.imageData) == null ? void 0 : _i.image) == null ? void 0 : _j.src) == null ? void 0 : _k.id) != null ? _l : imageSource,
    containerId: node.id,
    displayMode: "fill",
    width: width,
    height: height,
    uri: (_p = (_o = (_n = (_m = node.imageData) == null ? void 0 : _m.image) == null ? void 0 : _n.src) == null ? void 0 : _o.id) != null ? _p : imageSource,
    alt: altText || "",
    containerProps: {
      className: classNames(styles.imageWrapper, styles.imageRatio, className, (_classNames = {}, _classNames[styles.pointer] = !!expand$1, _classNames)),
      containerId: node.id,
      style: __spreadValues$1(__spreadValues$1((_spreadValues = {}, _spreadValues["--dim-height"] = height, _spreadValues["--dim-width"] = width, _spreadValues), fallbackWidthStyle), customWidthStyle)
    },
    imageStyles: {
      maxWidth: "100%"
    },
    socialAttrs: socialAttributes,
    isSEOBot: !!seoMode
  }, shouldLazyLoadImages ? {
    placeholderTransition: seoMode ? void 0 : "blur",
    shouldUseLQIP: (config == null ? void 0 : config.disableTransition) !== false
  } : {})), !!expand$1 && /* @__PURE__ */React.createElement("div", {
    className: styles.expandContainer,
    onClick: expand$1,
    onKeyDown: function onKeyDown(e) {
      if (e.key === "Enter" || e.key === " ") {
        expand$1(e);
      }
    },
    role: "button",
    tabIndex: 0
  }, /* @__PURE__ */React.createElement(expand, {
    className: styles.expandIcon
  })), captionComponent);
};

var ViewerImageCaption = function ViewerImageCaption(_ref) {
  var _classNames;
  var imageType = _ref.imageType,
    caption = _ref.caption,
    defaultCaption = _ref.defaultCaption;
  var isLinkImage = imageType === "link" || imageType === "anchor";
  var classes = classNames(styles.imageCaption, (_classNames = {}, _classNames[styles.link] = isLinkImage, _classNames));
  if (!caption || caption === defaultCaption) {
    return null;
  }
  return /* @__PURE__ */React.createElement("figcaption", {
    dir: "auto",
    className: classes
  }, caption);
};

function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) {
    for (var _iterator = _createForOfIteratorHelperLoose(__getOwnPropSymbols(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
    }
  }
  return a;
};
var __objRest = function __objRest(source, exclude) {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) {
    for (var _iterator2 = _createForOfIteratorHelperLoose(__getOwnPropSymbols(source)), _step2; !(_step2 = _iterator2()).done;) {
      var prop = _step2.value;
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
    }
  }
  return target;
};
var ViewerImage = function ViewerImage(_ref) {
  var node = _ref.node,
    nodeIndex = _ref.nodeIndex,
    NodeViewContainer = _ref.NodeViewContainer;
  var _a, _b;
  var _useContext = useContext(RicosViewerContext),
    viewerConfig = _useContext.config,
    seoMode = _useContext.seoMode,
    helpers = _useContext.helpers;
  var _useContext2 = useContext(RicosContext),
    isMobile = _useContext2.isMobile,
    t = _useContext2.t;
  var config = (_a = viewerConfig[IMAGE_TYPE$1]) != null ? _a : {};
  var expand = getExpandFunction({
    helpers: helpers,
    config: config,
    nodeId: node.id,
    imageData: node.imageData
  });
  var imageClickActionConfig = getImageClickActionConfig({
    imageData: node.imageData,
    config: config,
    expand: expand
  });
  return /* @__PURE__ */React.createElement(NodeViewContainer, {
    node: node,
    nodeIndex: nodeIndex,
    customClassNames: {
      alignment: alignmentClassName(node),
      size: sizeClassName(node, isMobile),
      textWrap: textWrapClassName(node)
    }
  }, /* @__PURE__ */React.createElement(Image$1, {
    node: node,
    imageClickActionConfig: imageClickActionConfig,
    expand: expand,
    config: config,
    seoMode: seoMode,
    helpers: helpers,
    isMobile: isMobile,
    shouldLazyLoadImages: true,
    captionComponent: /* @__PURE__ */React.createElement(ViewerImageCaption, {
      imageType: imageClickActionConfig.type,
      caption: (_b = node == null ? void 0 : node.imageData) == null ? void 0 : _b.caption,
      defaultCaption: t("ImageViewer_Caption")
    })
  }));
};
var ImageRenderer = function ImageRenderer(_a) {
  var _b = _a,
    node = _b.node,
    props = __objRest(_b, ["node"]);
  return /* @__PURE__ */React.createElement(ViewerImage, __spreadValues({
    key: node.id,
    node: node
  }, props));
};

export { ImageRenderer as default };

import 'wix-rich-content-plugin-image/dist/loadable/viewer/es/styles.viewer.min.global.css'